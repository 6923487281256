<template>
  <div>
    <!--begin::Contacts-->
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 v-if="!edit_form" class="card-label">Contact Add</h3>
          <h3 v-else class="card-label">Contact Update</h3>
        </div>
        <div class="card-toolbar">
          <div class="example-tools justify-content-center">
            <div class="my-2">
              <button
                type="button"
                class="mt-3 mr-1 mb-3 v-btn v-btn--depressed v-btn--flat v-btn--outlined theme--light v-size--default indigo--text"
              >
                <span class="v-btn__content" @click="redirectToContactsList()">
                  Back
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <form class="form" novalidate="novalidate" id="kt_contact_form">
          <!--begin::Example-->
          <div class="example mb-10">
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pr-2"
                    v-model="contact.title"
                    label="Title"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pr-2"
                    v-model="contact.first_name"
                    :rules="firstNameRules"
                    label="First name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    class="pr-2"
                    v-model="contact.last_name"
                    :rules="lastNameRules"
                    label="Last name"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="contact.organization"
                    label="Organization"
                    required
                    :rules="[v => !!v || 'Organization is required']"
                    class="pr-2"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="contact.email"
                    label="Email"
                    required
                    dense
                    outlined
                    :rules="emailRules"
                    class="pr-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    type="text"
                    v-model="contact.phone"
                    label="Phone (e.g. (800)444-4444 )"
                    placeholder="Company Name"
                    required
                    dense
                    outlined
                    :rules="phoneRules"
                    class="pr-2"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col cols="12" lg="12">
                  <v-textarea
                    v-model="contact.remarks"
                    label="Remarks"
                    dense
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row fluid class="card-footer pl-3 pt-3">
                <v-btn
                  v-if="edit_form"
                  :disabled="!valid"
                  color="success"
                  class="mr-4"
                  ref="kt_store_submit"
                  @click="validateEditForm"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Update
                </v-btn>
                <v-btn
                  v-else
                  :disabled="!valid || disable_btn"
                  color="success"
                  class="mr-4"
                  ref="kt_store_submit"
                  @click="validate"
                >
                <b-spinner v-if="disable_btn" label="Spinning"></b-spinner>
                  Save
                </v-btn>
              </v-row>
            </v-form>
          </div>
        </form>
      </div>
    </div>
    <!--end::Contacs-->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Vuetify from "vuetify";
import axios from "axios";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  STORE_CONTACT,
  UPDATE_CONTACT
} from "@/core/services/store/contact.module";
import Swal from "sweetalert2";
import KTUtil from "@/assets/js/components/util";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import EventBus from "@/core/services/store/event-bus";

export default {
  name: "ContactForm",
  vuetify: new Vuetify(),
  components: {},
  data() {
    return {
      contact: {
        title: "",
        first_name: "",
        last_name: "",
        organization: "",
        email: "",
        phone: "",
        remarks: ""
      },
      valid: true,
      disable_btn: false,
      firstNameRules: [
        v => !!v || "First Name is required",
        v =>
          (v && v.length <= 25) || "First Name must be less than 25 characters"
      ],
      lastNameRules: [
        v => !!v || "Last Name is required",
        v =>
          (v && v.length <= 25) || "Last Name must be less than 25 characters"
      ],
      phoneRules: [
        v => !!v || "Valid phone number is required",
        v =>
          /^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/i.test(v) ||
          "Phone number must be valid"  
      ],
      emailRules: [
        v => !!v || "E-mail is required",
        v =>
          /^\b[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\b$/i.test(v) ||
          "E-mail must be valid"
      ],
      edit_form: false
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    ...mapGetters(["companyUsers"])
  },
  mounted() {
    let context = this;
    if (this.$route.params.id) {
      context.edit_form = true;
      axios({
        method: "get",
        url: "contacts/" + this.$route.params.id,
        baseURL: process.env.VUE_APP_ACBACK_URL
      }).then(
        function(result) {
          if (result.data.contacts) {
            context.contact = result.data.contacts;
          } else {
            // context.$router.go(-1); //go back to list
            context.disable_btn = false;
            Swal.fire(result.data.error, "Contact not found!", "error");
          }
        },
        function(error) {
          context.disable_btn = false;
          Swal.fire("Error", "Contact not found.", "error");
          context.$router.push({ name: "ContactList" });
        }
      );
    }

    // set the tab from previous
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Contact" }]);

    const contact_form = KTUtil.getById("kt_contact_form");

    this.fv = formValidation(contact_form, {});

    EventBus.$on("UPDATE_CONTACT_ERROR", function(payLoad) {
      context.$router.push({ name: "ContactList" });
    });
  },
  destroyed() {
    localStorage.removeItem("builderTab");
  },
  methods: {
    updateContact() {
      let context = this;
      this.$store.dispatch(UPDATE_CONTACT, this.contact).then(() => {
        context.$router.push({ name: "ContactList" });
      },
      function(error) {
        Swal.fire("Error", "Contact not found.", "error");
      }
      );
    },
    redirectToContactsList() {
      let context = this;
      context.$router.go(-1);
    },
    submitContact() {
      let context = this;
      this.$store.dispatch(STORE_CONTACT, this.contact).then(() => {
        context.disable_btn = false;
        context.$router.push({ name: "ContactList" });
      });
    },
    validateEditForm() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.updateContact();
      } else {
        this.snackbar = true;
      }
    },
    validate() {
      let context = this;
      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "",
          text: "Please, provide correct data!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
      if (this.$refs.form.validate()) {
        context.disable_btn = true;
        this.submitContact();
      } else {
        this.snackbar = true;
      }
    }
  }
};
</script>
